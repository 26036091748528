import React, { useState, useEffect, useRef } from 'react';
import { Image } from '@nextui-org/react';
import axios from 'axios';
import { router, Head } from '@inertiajs/react';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';
import { Box, Card, MenuItem, Checkbox, Divider, Select, CardContent, FormControl, FormControlLabel, InputLabel, TextField, Button, Typography, Modal, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Grid from '@mui/material/Grid2';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    boxShadow: '0 5px 15px rgba(0,0,0,0.2)',
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
}));

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

interface Currency {
    id: number;
    symbol: string;
    name: string;
    image_url: string;
    min_exchange_amount: string;
    max_exchange_amount: string;
    reserve: number;
}

interface FakeExchangeRequest {
    created_at: string;
    from_currency: Currency;
    to_currency: Currency;
    amount_give: number;
    amount_receive: number;
    wallet_address: string;
    status: string;
}

interface WelcomeProps {
    giveCurrencies: Currency[];
    getCurrencies: Currency[];
    rate?: number;
    error?: string;
    fakeExchanges?: FakeExchangeRequest[]; // Новый пропс
    reviews: Review[]; // Добавлено поле отзывов
}

interface WelcomeProps {
    giveCurrencies: Currency[];
    getCurrencies: Currency[];
    rate?: number;
    error?: string;
}

interface Review {
    id: number;
    name: string;
    email: string;
    rating: number;
    description: string;
    image_feedback?: string;
    id_exchange: number;
    verified: boolean;
    image_feedback_url?: string; // Если используется кастомный атрибут для URL изображения
}

const Welcome: React.FC<WelcomeProps> = ({ giveCurrencies, getCurrencies, rate, fakeExchanges, error, reviews }) => {
    const [isModalOpen, setIsModalOpen] = useState(false); // Состояние модального окна

    const [selectedGiveCurrency, setSelectedGiveCurrency] = useState<string>(giveCurrencies[0]?.id.toString() || '');
    const [selectedGetCurrency, setSelectedGetCurrency] = useState<string>(() => {
        // Устанавливаем первую доступную валюту, которая не совпадает с "отдаю"
        const defaultGetCurrency = getCurrencies.find(currency => currency.id.toString() !== giveCurrencies[0]?.id.toString());
        return defaultGetCurrency ? defaultGetCurrency.id.toString() : '';
    });
    const buttonGiveRef = useRef<HTMLButtonElement | null>(null);
    const buttonGetRef = useRef<HTMLButtonElement | null>(null);

    // Позиции выпадающих списков
    const [giveDropdownPosition, setGiveDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
    const [getDropdownPosition, setGetDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

    // Видимость выпадающих списков
    const [isGiveDropdownOpen, setIsGiveDropdownOpen] = useState(false);
    const [isGetDropdownOpen, setIsGetDropdownOpen] = useState(false);

    const calculateDropdownPosition = (
        buttonRef: React.RefObject<HTMLButtonElement>,
        setDropdownPosition: React.Dispatch<React.SetStateAction<{ top: number; left: number; width: number; }>>
    ) => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setDropdownPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
            });
        }
    };

    const handleOpenModal = () => setIsModalOpen(true); // Функция для открытия модального окна
    const handleCloseModal = () => setIsModalOpen(false); // Функция для закрытия модального окна

    const toggleGiveDropdown = () => {
        setIsGiveDropdownOpen(!isGiveDropdownOpen);
        if (!isGiveDropdownOpen) {
            calculateDropdownPosition(buttonGiveRef, setGiveDropdownPosition);
        }
    };

    const toggleGetDropdown = () => {
        setIsGetDropdownOpen(!isGetDropdownOpen);
        if (!isGetDropdownOpen) {
            calculateDropdownPosition(buttonGetRef, setGetDropdownPosition);
        }
    };

    const scrollToExchange = () => {
        if (window.location.pathname === "/") {
            document.getElementById("exchange")?.scrollIntoView({ behavior: "smooth" });
        } else {
            router.visit("/#exchange");
        }
    };

    useEffect(() => {
        if (isGiveDropdownOpen || isGetDropdownOpen) {
            document.body.style.overflow = 'hidden'; // Блокировка прокрутки страницы
        } else {
            document.body.style.overflow = 'auto'; // Разблокировка прокрутки
        }

        // Очистка эффекта при размонтировании компонента
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isGiveDropdownOpen, isGetDropdownOpen]);


    const selectedGiveCurrencyObj = giveCurrencies.find((currency) => currency.id.toString() === selectedGiveCurrency);
    const selectedGetCurrencyObj = getCurrencies.find((currency) => currency.id.toString() === selectedGetCurrency);

    const [amount, setAmount] = useState<string>('1117');
    const [receivedAmount, setReceivedAmount] = useState<string>('');
    const [exchangeRate, setExchangeRate] = useState<number | null>(rate || null);
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [timer, setTimer] = useState<number>(15);
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

    // Состояния для чекбоксов
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [isAMLAccepted, setIsAMLAccepted] = useState<boolean>(false);

    // Состояния для ошибок
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isFormValid, setIsFormValid] = useState<boolean>(false); // Состояние для отслеживания валидности формы
    const [isLoading, setIsLoading] = useState<boolean>(false); // Новое состояние для загрузки

    // Функция для получения курса с помощью axios
    const fetchExchangeRate = async (giveCurrencyId: string, getCurrencyId: string) => {
        try {
            const response = await axios.post('/get-exchange-rate', {
                give_currency_id: giveCurrencyId,
                get_currency_id: getCurrencyId,
            });

            if (response.data.rate) {
                setExchangeRate(response.data.rate);
            }
        } catch (error) {
            console.error('Ошибка при получении курса', error);
        }
    };

    // Рассчитываем получаемую сумму при изменении курса или суммы
    useEffect(() => {
        if (exchangeRate && amount) {
            const result = (parseFloat(amount) * exchangeRate).toFixed(2);
            setReceivedAmount(result);
        } else {
            setReceivedAmount('');
        }
    }, [amount, exchangeRate]);

    // Обновляем курс при изменении валют
    useEffect(() => {
        fetchExchangeRate(selectedGiveCurrency, selectedGetCurrency);
    }, [selectedGiveCurrency, selectedGetCurrency]);

    // Таймер для обновления курса каждые 15 секунд
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer((prev) => {
                if (prev === 1) {
                    fetchExchangeRate(selectedGiveCurrency, selectedGetCurrency);
                    return 15;
                } else {
                    return prev - 1;
                }
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [selectedGiveCurrency, selectedGetCurrency]);

    // Функция для обмена валют
    const swapCurrencies = () => {
        const previousGiveCurrency = selectedGiveCurrency;
        setSelectedGiveCurrency(selectedGetCurrency);
        setSelectedGetCurrency(previousGiveCurrency);
    };

    const handleGiveCurrencyChange = (value: { value: string, label: React.ReactNode }) => {
        setSelectedGiveCurrency(value.value); // Сохраняем выбранную валюту
    };

    const handleGetCurrencyChange = (value: { value: string, label: React.ReactNode }) => {
        setSelectedGetCurrency(value.value); // Сохраняем выбранную валюту
    };

    const validateFields = () => {
        const newErrors: { [key: string]: string } = {};

        // Проверка суммы
        if (!amount || isNaN(Number(amount)) || parseFloat(amount) <= 0) {
            newErrors.amount = 'Введите корректную сумму';
        } else {
            const minAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.min_exchange_amount || '0');
            const maxAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.max_exchange_amount || '0');

            if (parseFloat(amount) < minAmount || parseFloat(amount) > maxAmount) {
                newErrors.amount = `Сумма должна быть в пределах от ${minAmount} до ${maxAmount}`;
            }
        }

        // Проверка кошелька
        if (!walletAddress) {
            newErrors.walletAddress = 'Введите адрес кошелька';
        }

        // Проверка email
        if (!email) {
            newErrors.email = 'Введите email';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newErrors.email = 'Введите корректный email';
        }

        // Проверка чекбоксов
        if (!isTermsAccepted || !isAMLAccepted) {
            newErrors.terms = 'Пожалуйста, примите все условия';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Валидация отдельных полей в реальном времени
    const validateAmount = (value: string) => {
        let error = '';
        const minAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.min_exchange_amount || '0');
        const maxAmount = parseFloat(giveCurrencies.find(currency => currency.id.toString() === selectedGiveCurrency)?.max_exchange_amount || '0');

        if (!value || isNaN(Number(value)) || parseFloat(value) <= 0) {
            error = 'Введите корректную сумму';
        } else if (parseFloat(value) < minAmount || parseFloat(value) > maxAmount) {
            error = `Сумма должна быть в пределах от ${minAmount} до ${maxAmount}`;
        }
        setErrors(prev => ({ ...prev, amount: error }));
        checkFormValidity();
    };

    const validateWalletAddress = (value: string) => {
        let error = value ? '' : 'Введите адрес кошелька';
        setErrors(prev => ({ ...prev, walletAddress: error }));
        checkFormValidity();
    };

    const validateEmail = (value: string) => {
        let error = '';

        if (!value) {
            error = 'Введите email';
        } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
            error = 'Введите корректный email';
        }

        setErrors(prev => ({ ...prev, email: error }));
        checkFormValidity();
    };

    // Обновление `checkFormValidity`
    const checkFormValidity = () => {
        const isValid = !errors.amount && !errors.walletAddress && !errors.email &&
            !!amount && !!walletAddress && !!email &&
            isTermsAccepted && isAMLAccepted;

        setIsFormValid(isValid);
    };

    // Обработка отправки данных
    const handleSubmit = async () => {
        if (!validateFields()) {
            setErrors({ ...errors, submit: 'Необходимо исправить ошибки' });
            return; // Прекращаем выполнение, если есть ошибки
        }

        setIsLoading(true);
        try {
            const response = await axios.post('/exchange/create', {
                give_currency_id: selectedGiveCurrency,
                get_currency_id: selectedGetCurrency,
                amount_give: amount,
                amount_receive: receivedAmount,
                wallet_address: walletAddress,
                email: email,
            });

            if (response.data.success) {
                router.visit(`/exchange/${response.data.request_id}`);
            }
        } catch (error) {
            console.error('Ошибка при создании заявки', error);
            setErrors({ ...errors, submit: 'Ошибка при создании заявки' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkFormValidity();
    }, [errors, amount, walletAddress, email, isTermsAccepted, isAMLAccepted]);


    const openChatra = () => {
        if (window.Chatra) {
            window.Chatra('openChat', true);
        } else {
            console.error('Chatra is not loaded yet.');
        }
    };

    return (
        <AuthenticatedLayout>
            <Head>
                <title>Главная</title>
                <meta name="description" content="Enzer Exchange" />
            </Head>
            <div >
                <Box id="exchanga">
                    <Grid container spacing={4} alignItems="center" justifyContent="center">
                        {/* Левая секция - описание сервиса */}
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Box display="flex" flexDirection="column" alignItems="flex-center" gap={2}>
                                <center><Image src="/assets/img/bixbi.png" /></center>
                                <Typography className='text-center' color="textPrimary" variant="h5" fontWeight="bold">
                                    Криптовалютный обменный сервис
                                </Typography>
                                <Typography className='text-center' color="text.primary" variant="body2">
                                    Более 50 пунктов приема и выдачи наличных в России.<br />
                                    Безопасные и конфиденциальные денежные операции.<br />
                                    Перевод наличных между Россией и 193 странами мира.
                                </Typography>
                                <Box className="flex justify-center items-center" gap={2}>
                                    <img src="/assets/img/partn/exmo.svg" alt="USDT" className="w-8 h-auto" />
                                    <img src="/assets/img/partn/adv.png" alt="BTC" className="w-16 h-auto" />
                                    <img src="/assets/img/partn/epay.svg" alt="SWIFT" className="w-16 h-auto" />
                                </Box>
                            </Box>
                        </Grid>

                        {/* Правая секция - форма обмена */}
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Card variant="outlined" sx={{ p: 2, backgroundColor: 'background.paper' }}>
                                <CardContent>
                                    <Box className="border border-green-400 rounded p-2" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Typography variant="body1" fontWeight="bold">
                                            Обновление курса
                                        </Typography>
                                        <Typography className="bg-green-400 rounded px-2 py-1">
                                            Auto
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ my: 4 }} />

                                    <Box display="flex" flexDirection="column" gap={2}>
                                        <Box className="flex flex-col space-y-3" >
                                            <Typography className='flex justify-between items-center pb-2' variant="body1" fontWeight="bold">
                                                <span>Оплачиваем {selectedGiveCurrencyObj?.symbol || 'Валюту'} <CallMissedOutgoingIcon /></span>
                                                <span className='hidden lg:block'>От <span className="mr-1" style={{ color: '#4caf50' }}>{selectedGiveCurrencyObj?.min_exchange_amount || 'N/A'}</span>{selectedGiveCurrencyObj?.symbol || 'N/A'}</span>
                                            </Typography>
                                            <TextField
                                                value={amount}
                                                onChange={(e) => {
                                                    setAmount(e.target.value);
                                                    validateAmount(e.target.value); // Вызов функции валидации суммы
                                                }}
                                                placeholder="0.00"
                                                variant="outlined"
                                                label="Я хочу отдать"
                                                fullWidth
                                                sx={{ mr: 2 }}
                                                error={Boolean(errors.amount)}
                                                helperText={errors.amount}
                                            />
                                            <FormControl variant="outlined">
                                                <InputLabel>Валюта отдачи</InputLabel>
                                                <Select
                                                    fullWidth
                                                    value={selectedGiveCurrency}
                                                    onChange={(e) => setSelectedGiveCurrency(e.target.value as string)}
                                                    label="Валюта отдачи"
                                                >
                                                    {giveCurrencies.map((currency) => (
                                                        <MenuItem key={currency.id} value={currency.id.toString()}>
                                                            <Box display="flex" alignItems="center">
                                                                <img src={currency.image_url} alt={currency.symbol} style={{ width: 24, height: 24, marginRight: 8 }} />
                                                                <span>{currency.symbol}</span>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Button onClick={swapCurrencies} startIcon={<SwapHorizIcon />} variant="outlined" color="primary">
                                            Поменять
                                        </Button>

                                        <Box className="flex flex-col space-y-3" >
                                            <Typography className='flex justify-between items-center pb-2' variant="body1" fontWeight="bold">
                                                <span>Пополняем {selectedGetCurrencyObj?.symbol || 'отдаю'} <CallMissedIcon /></span>
                                                <span className='hidden lg:block'>Резерв: <span style={{ color: '#4caf50' }}>{selectedGetCurrencyObj?.reserve || 'N/A'} {selectedGetCurrencyObj?.symbol}</span></span>

                                            </Typography>
                                            <TextField
                                                value={receivedAmount}
                                                placeholder="0.00"
                                                variant="outlined"
                                                label="Я хочу получить"
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                            />
                                            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                                <InputLabel>Валюта получения</InputLabel>
                                                <Select
                                                    value={selectedGetCurrency}
                                                    onChange={(e) => setSelectedGetCurrency(e.target.value as string)}
                                                    label="Валюта получения"
                                                >
                                                    {getCurrencies.map((currency) => (
                                                        <MenuItem key={currency.id} value={currency.id.toString()}>
                                                            <Box display="flex" alignItems="center">
                                                                <img src={currency.image_url} alt={currency.symbol} style={{ width: 24, height: 24, marginRight: 8 }} />
                                                                <span>{currency.symbol}</span>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>

                                    {errors.amount && (
                                        <Typography color="error">{errors.amount}</Typography>
                                    )}

                                    <Divider sx={{ my: 4 }} />

                                    <Button
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        onClick={handleOpenModal}
                                    >
                                        Перейти к созданию заявки
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>


                {/* Модальное окно */}
                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="exchange-modal-title"
                    aria-describedby="exchange-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="exchange-modal-title" variant="h6" component="h2" className='text-center' gutterBottom>
                            Подготовка заявки
                        </Typography>

                        <Divider sx={{ mb: 2 }} />

                        {/* Поля формы */}
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, justifyContent: 'space-between', gap: 2 }}>
                            <Box>
                                <Box className="flex flex-col space-y-3" >
                                    <TextField
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value);
                                            validateAmount(e.target.value); // Вызов функции валидации суммы
                                        }}
                                        disabled={true}
                                        slotProps={{
                                            input: {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {selectedGiveCurrencyObj?.image_url && (
                                                            <img
                                                                src={selectedGiveCurrencyObj.image_url}
                                                                alt={selectedGiveCurrencyObj.symbol}
                                                                className='h-auto w-8'
                                                            />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {selectedGiveCurrencyObj?.symbol && (
                                                            <span>{selectedGiveCurrencyObj.symbol}</span>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                        placeholder="0.00"
                                        variant="outlined"
                                        label="Вы отдадите"
                                        fullWidth
                                        sx={{ mr: 2 }}
                                        error={Boolean(errors.amount)}
                                        helperText={errors.amount}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Box className="flex flex-col space-y-3">
                                    <TextField
                                        value={receivedAmount}
                                        placeholder="0.00"
                                        variant="outlined"
                                        label="Вы получите"
                                        fullWidth
                                        sx={{ mr: 2 }}
                                        disabled={true}
                                        slotProps={{
                                            input: {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {selectedGetCurrencyObj?.image_url && (
                                                            <img
                                                                src={selectedGetCurrencyObj.image_url}
                                                                alt={selectedGetCurrencyObj.symbol}
                                                                className='h-auto w-8'
                                                            />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {selectedGetCurrencyObj?.symbol && (
                                                            <span>{selectedGetCurrencyObj.symbol}</span>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        {/* Ошибки */}
                        {errors.amount && <Typography color="error">{errors.amount}</Typography>}

                        <Divider sx={{ my: 4 }} />

                        {/* Поля ввода */}
                        <StyledFormControl>
                            <TextField
                                label="Адрес кошелька"
                                value={walletAddress}
                                onChange={(e) => {
                                    setWalletAddress(e.target.value);
                                    validateWalletAddress(e.target.value); // Вызов функции валидации адреса кошелька
                                }}
                                placeholder="Введите адрес кошелька"
                                variant="outlined"
                                fullWidth
                                error={Boolean(errors.walletAddress)}
                                helperText={errors.walletAddress}
                            />
                        </StyledFormControl>

                        <StyledFormControl>
                            <TextField
                                value={email}
                                label="Ваш Email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    validateEmail(e.target.value); // Вызов функции валидации email
                                }}
                                placeholder="example@example.com"
                                variant="outlined"
                                fullWidth
                                error={Boolean(errors.email)}
                                helperText={errors.email}
                            />
                        </StyledFormControl>

                        {/* Чекбоксы для условий */}
                        <FormControlLabel
                            control={<Checkbox checked={isTermsAccepted} onChange={(e) => setIsTermsAccepted(e.target.checked)} />}
                            label="Соглашаюсь с Правилами"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={isAMLAccepted} onChange={(e) => setIsAMLAccepted(e.target.checked)} />}
                            label="Соглашаюсь с AML/CTF & KYC"
                        />

                        {/* Кнопка отправки */}
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 3 }}
                            onClick={handleSubmit}
                            disabled={!isFormValid || isLoading} // Блокируем кнопку, если форма невалидна или происходит загрузка
                        >
                            Приступить к обмену
                        </Button>
                    </Box>
                </Modal>

            </div>
            <div className="relative ">
                <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                    <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                        style={{ background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)" }}>
                    </div>
                </div>
                <Box sx={{ py: 8, backgroundColor: 'background.default', color: 'text.primary' }}>
                    <Grid container spacing={4} justifyContent="center">
                        {/* Последние обмены */}
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Последние обмены
                            </Typography>
                            <Grid
                                container
                                spacing={2}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                sx={{ flexWrap: 'wrap' }}
                            >
                                {fakeExchanges && fakeExchanges.length > 0 ? (
                                    fakeExchanges.map((exchange, index) => (
                                        <Grid key={index}>
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    backgroundColor: 'background.paper',
                                                    display: 'inline-flex',
                                                    minWidth: 180,
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <CardContent
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                        padding: '8px 16px', // Установка собственного отступа
                                                        paddingBottom: '8px !important', // Убираем дополнительный отступ снизу
                                                    }}
                                                >
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        <img
                                                            src={exchange.from_currency.image_url}
                                                            alt={exchange.from_currency.symbol}
                                                            style={{ width: 24, height: 24 }}
                                                        />
                                                        <Typography className='flex flex-col lg:flex-row' variant="body2" fontWeight="bold">
                                                            <span>{Number(exchange.amount_give).toFixed(3)}</span> <span>{exchange.from_currency.symbol}</span>
                                                        </Typography>
                                                    </Box>
                                                    <CompareArrowsIcon />
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        <Typography variant="body2" className='flex flex-col lg:flex-row' fontWeight="bold">
                                                            <span>{Number(exchange.amount_receive).toFixed(2)}</span> <span>{exchange.to_currency.symbol}</span>
                                                        </Typography>
                                                        <img
                                                            src={exchange.to_currency.image_url}
                                                            alt={exchange.to_currency.symbol}
                                                            style={{ width: 24, height: 24 }}
                                                        />
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="text.secondary">
                                        Нет фейковых обменов
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>

                        {/* Нам доверяют */}
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Box textAlign="center">
                                <Typography className='flex items-center' variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                    Нам доверяют <Image src="/assets/img/trustpilot.svg" className='ml-2 w-32' />
                                </Typography>
                            </Box>
                            <Grid container spacing={2} sx={{ justifyContent: { xs: 'center', md: 'start' } }}>
                                {reviews.map((review) => (
                                    <Grid size={{ xs: 12, md: 6 }} key={review.id}>
                                        <Card variant="outlined" sx={{ p: 2, backgroundColor: 'background.paper' }}>
                                            <CardContent>
                                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon" data-v-37112b2b=""><path d="M15 5.5C6.75 5.5 0 12.25 0 20.5V35.5H15V20.5H5C5 14.95 9.45 10.5 15 10.5V5.5ZM34 5.5C25.75 5.5 19 12.25 19 20.5V35.5H34V20.5H24C24 14.95 28.45 10.5 34 10.5V5.5Z" fill="#00B67A"></path></svg>
                                                <Image className='my-3' src="/assets/img/icons/5star.svg" />
                                                <Typography variant="body2" sx={{ my: 1 }}>
                                                    {review.description}
                                                </Typography>
                                                <Box display="flex" alignItems="center" gap={1} mt={2}>
                                                    <Box>
                                                        <Typography variant="body2" fontWeight="bold">
                                                            {review.email}
                                                        </Typography>
                                                        <Typography variant="caption" color="textSecondary">
                                                            {review.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <section className="py-10 sm:py-16 lg:py-24">
                <div className="mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-16 lg:gap-x-24 gap-y-10">
                        <div>
                            <img className="object-cover w-16 h-16 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/cta/9/female-avatar-1.png" alt="" />
                            <blockquote>
                                <Typography color="textSecondary" className="pt-6 text-xl leading-relaxed">“Это один из лучших обменных сервисов которые я встречала! Спасибо за вашу работу и заботу о каждом клиенте!”</Typography>
                            </blockquote>
                            <Typography color="textPrimary" className="pt-6 text-base font-semibold">Антонина Вольфман</Typography>
                            <Typography color="textPrimary" className="mt-1 text-base">Инфлюенсер</Typography>
                        </div>

                        <div>
                            <Card variant="outlined">
                                <div className="p-8 lg:px-12 lg:py-10">
                                    <Typography variant="h4" color="textPrimary" className="text-2xl font-semibold text-black">Присоеденийся к обмену вместе с более чем 500+ пользователей</Typography>
                                    <Typography variant="body1" color="textPrimary" className="mt-4 text-base">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis.</Typography>

                                    <a href="#exchanga" title="" className="flex items-center justify-center w-full px-4 py-4 mt-8 text-base font-semibold text-black transition-all duration-200 bg-green-400 border-2 border-transparent rounded-md hover:bg-green-700 focus:bg-green-700" role="button">
                                        Вернуться к обмену
                                    </a>

                                    <a href="#" title="" className="flex items-center justify-center w-full px-4 py-4 mt-4 text-base font-semibold transition-all duration-200 bg-transparent border-2 border-green-400 rounded-md hover:bg-green-400 focus:bg-green-800 hover:text-black focus:text-black" role="button">
                                        Вопросы-ответы
                                    </a>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </AuthenticatedLayout>
    );
};

export default Welcome;
